import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import { bus } from '../../main';

export default{
    name : 'InstantMeetingDetailsComponent',
    components:{
        Multiselect,
        draggable,
    },
    data(){
        return{
            today_date : moment().format(),
            action_types : [this.Constants.TASK_TYPE.TYPE_1,this.Constants.TASK_TYPE.TYPE_2,this.Constants.TASK_TYPE.TYPE_3,this.Constants.TASK_TYPE.TYPE_4,this.Constants.TASK_TYPE.TYPE_5,this.Constants.TASK_TYPE.TYPE_6],
            meeting_agendas : this.type == 'Instant_meeting' ? this.agenda_details : [],
            assigne_obj : JSON.parse(sessionStorage.getItem('all_participates')) ?  JSON.parse(sessionStorage.getItem('all_participates')) : [],
            issue_obj : [this.Constants.PRIORITY.TYPE_1,this.Constants.PRIORITY.TYPE_2,this.Constants.PRIORITY.TYPE_3,this.Constants.PRIORITY.TYPE_4],
            meeting_data : this.type == 'Instant_meeeting' ? JSON.parse(sessionStorage.getItem('meet_details')) : {},
            hover_index : 0,
            minutes_index : 0,
            minute_type : '',
            add_click_enable : true,
            tab_row_add : false,
        }
    },

    props: {
        type : String,
        agenda_details : Array,
        agenda_focus_index : Number,
        minute_focus_index: Number,
        tab_index_changed:Boolean
    },

    watch:{
        meeting_agendas:{
            handler(){
                this.$emit('minutes_type_count');
                if( this.type == 'Instant_meeting'){
                    this.meeting_agendas = this.agenda_details;
                }
            },
            deep: true
        },

        agenda_details:{
            handler(){
                this.meeting_agendas = this.agenda_details;
            },
            deep: true
        },

        agenda_focus_index(){
            if(!this.tab_index_changed){
            this.$refs.editor[this.agenda_focus_index].focus();
            }
            this.hover_index = this.agenda_focus_index;
            this.minutes_index = -1;
            this.minute_type = this.Constants.TASK_TYPE.TYPE_5;
        },

        minute_focus_index(){
            if(this.$refs['instant_data_editor_'+this.hover_index][this.minute_focus_index-1]){
                this.$refs['instant_data_editor_'+this.hover_index][this.minute_focus_index].focus();
            }else if(this.$refs['instant_data_editor_'+(this.hover_index-1)]){
                this.$refs['editor'][this.hover_index].focus();
            }         
            this.minutes_index = this.minute_focus_index;
        },

        hover_index(val){
            localStorage.setItem("h_i",val);
            localStorage.setItem("m_i",this.minutes_index);

        },

        minutes_index(val1){
            localStorage.setItem("m_i",val1);
            localStorage.setItem("h_i",this.hover_index);
        },
    },
    
    directives:{
        default_format:{ 
            update(el){
                let original_date_input = el.value;

                if(el.value ==''){
                    el.value = moment().add(1, 'days').format("DD MMM YYYY");
                }else if(moment(el.value).format("DD MMM, YYYY") == "Invalid date"){
                    el.value = moment(el.value, "DD MM YYYY").format("DD MMM YYYY")
                }else{
                    el.value = moment(el.value).format("DD MMM YYYY");
                }

                if(original_date_input.match(/[a-zA-Z]/) != null){
                    if(!Number.isInteger(parseInt(original_date_input.slice(-2)))){
                        el.value = moment(original_date_input, "DD MMM YYYY").format("DD MMM YYYY")
                    }else if(!Number.isInteger(parseInt(original_date_input.split("").reverse().join("").slice(-2)))){
                        el.value = moment(original_date_input, "MMM DD YYYY").format("DD MMM YYYY")
                    }

                    if(Number.isInteger(parseInt(original_date_input.slice(-2))) || Number.isInteger(parseInt(original_date_input.slice(-1)))){
                        if(Number.isInteger(parseInt(original_date_input.slice(-4)))){
                            el.value = moment(original_date_input).format('DD MMM YYYY');
                        }else{
                            el.value = moment(moment(original_date_input,'MMM DD').toString()).format("DD MMM YYYY");
                        }
                        
                    }else if(Number.isInteger(parseInt(original_date_input.split("").reverse().join("").slice(-2)))){
                        el.value = moment(original_date_input, "DD MMM YYYY").format("DD MMM YYYY")
                    }

                }else if(original_date_input.replace(/\D/g, "").length <= 4 && original_date_input != ""){
                    el.value = moment(original_date_input, "DD MM YYYY").format("DD MMM YYYY")

                    if(el.value == "Invalid date"){
                        el.value = moment(original_date_input, "MM DD YYYY").format("DD MMM YYYY")
                    }
                }
            },

        },
        remove_break:{
            inserted(el){ 
                if(el.innerHTML == '<br>'){
                    el.innerHTML = '';
                }
            }
        }
    },

    async created(){
//        document.addEventListener('keydown', this.keyactive) depricated some reasons
        if(this.type == 'Instant_meeting'){
            this.meeting_data = JSON.parse(sessionStorage.getItem('meet_details'));
            if(!sessionStorage.getItem('agenda_details')){
                this.meeting_agendas.push(this.meeting_data.agenda);
                this.meeting_agendas[0].minutes = [];
                this.meeting_data.minutes.parameters = {
                                                        "asignee_id" : "",
                                                        "status" : "Open",
                                                        "due_date": moment().add('1','days').format("YYYY-MM-DD")
                                                        };
                this.meeting_agendas[0].minutes[0] = this.meeting_data.minutes;
                sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
            }
            this.$emit('minutes_type_count'); 
        }else{
            let meeting_id = this.$route.params.meeting_id;
            await this.$store.dispatch('getMeetingParticipants',[this.$store.state.api, { "meeting_id": meeting_id}]);
            this.meeting_data = this.$store.state.meeting_minutes_details;
            this.meeting_agendas = this.$store.state.meeting_minutes_details.agenda;
            this.assigne_obj = this.$store.state.meeting_participants;
        }

        bus.$on('all_participates', (data) => {
            this.assigne_obj = data;
        })  
        
        //This api call is used for both instant meeting and draft meeting.
        await this.getChangelogDetails(this.meeting_data ? this.meeting_data.meeting._id :this.$route.params.meeting_id);

    },
    //not using that the reason commented
//    beforeDestroy() {
//        document.removeEventListener('keydown', this.keyactive)
//    }, 

    async  mounted(){        
        let _this = this;
        window.setTimeout(function(){
            if(_this.$refs.date_picker){
                _this.$refs.date_picker[0].focus();
                _this.$refs.date_picker[0].blur();
            }
            if(_this.$refs.editor){
                if(!_this.tab_index_changed){
                    _this.$refs.editor[0].focus();
                    _this.hover_index = 0;
                    _this.minutes_index = -1;
                    
                }else{
                    let hov_id = localStorage.getItem("h_i")
                    let min_id = localStorage.getItem("m_i")
                    if(hov_id==hov_id && min_id == -1){
                        _this.$refs.editor[hov_id].focus(); 
                    }else{
                    _this.$refs['instant_data_editor_'+hov_id][min_id].focus()
                    }
                    _this.hover_index = hov_id;
                    _this.minutes_index = min_id;
                }
            }
            _this.minute_type = _this.Constants.TASK_TYPE.TYPE_5
        },100);
    },

    methods:{
        async onblur_add_row(index,min_index){
            let total_agendas = this.meeting_agendas.length;
            let total_last_agenda_minutes = this.meeting_agendas[total_agendas-1].minutes.length;
            var min_id = this.meeting_agendas[total_agendas-1].minutes[total_last_agenda_minutes -1];

            this.hover_index = parseInt(index);
            this.minutes_index =parseInt(min_index);

            var hov_min_id = this.meeting_agendas[this.hover_index].minutes[this.minutes_index];
            if(min_id == hov_min_id ){
                this.tab_row_add = true;
                await this.instant_meet_count_change(this.hover_index,this.minutes_index,'add');
            }
        },
        async keyactive(){  // this function not implementd some reasons content length function -> this.getCaretCharacterOffsetWithin(el) not gettign properl
//            if(e.which == 40){ 
//                var indx = (1/2*(this.hover_index + this.minutes_index)*(this.minutes_index + this.hover_index+1))+this.minutes_index;
//                if(this.hover_index==this.hover_index && this.minutes_index == -1){
//                    indx = 'h_i'+this.hover_index;
//                }
//                var el = document.getElementById(indx);
//                var text = el.textContent; 
//                if(text.length == this.getCaretCharacterOffsetWithin(el) && !e.srcElement.dataset.divtype){
//                    e.preventDefault();
//                    if(this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index+1]){
//                        this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index+1].focus();
//                        this.minutes_index += 1;
//                    }else if(this.$refs['instant_data_editor_'+(this.hover_index+1)]){
//                        this.$refs['editor'][this.hover_index+1].focus();
//                        this.hover_index += 1;
//                        this.minutes_index = -1;
//                    }
//                }   
//            }
//
//            if(e.which == 38){
//                var minlenid = this.meeting_agendas[this.hover_index].minutes.length;
//                var indx1 = (1/2*(this.hover_index+this.minutes_index)*(this.minutes_index+this.hover_index+1))+this.minutes_index;
//                var minlenvalue = (1/2*((this.hover_index)+minlenid)*(minlenid+(this.hover_index)+1))+minlenid;
//                if(indx1 == minlenvalue ) {
//                    indx1 = 'h_i'+(this.hover_index+1); 
//                } 
//
//                if(this.hover_index == this.hover_index && this.minutes_index == -1){
//                    indx1 = 'h_i'+this.hover_index;
//                }
//                var ele = document.getElementById(indx1)   
//                var text1 = ele.textContent; 
//                var startpos = text1.length - text1.length;
//                if(startpos == this.getCaretCharacterOffsetWithin(ele) && !e.srcElement.dataset.divtype){
//                    e.preventDefault();
//                    if(this.minutes_index!= -1){
//                        if(this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index-1]){
//                            this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index-1].focus();
//                            this.minutes_index -= 1;
//                        }else if(this.$refs['instant_data_editor_'+(this.hover_index)]){
//                            this.$refs['editor'][this.hover_index].focus();
//                            this.minutes_index = -1;
//                        }
//                    }else{
//                        if(this.$refs['instant_data_editor_'+(this.hover_index-1)]){
//                            this.hover_index -= 1;
//                            this.minutes_index =this.meeting_agendas[this.hover_index].minutes.length-1;
//                            this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index].focus();
//                        }
//                    }   
//                }
//            }
        },

        getCaretCharacterOffsetWithin(element) {
            var caretOffset = 0;
            var doc = element.ownerDocument || element.document;
            var win = doc.defaultView || doc.parentWindow;
            var sel;
            if (typeof win.getSelection != "undefined") {
                sel = win.getSelection();
                if (sel.rangeCount > 0) {
                    var range = win.getSelection().getRangeAt(0);
                    var preCaretRange = range.cloneRange();
                    preCaretRange.selectNodeContents(element);
                    preCaretRange.setEnd(range.endContainer, range.endOffset);
                    caretOffset = preCaretRange.toString().length;
                }
            } else if ((sel = doc.selection) && sel.type != "Control") {
                var textRange = sel.createRange();
                var preCaretTextRange = doc.body.createTextRange();
                preCaretTextRange.moveToElementText(element);
                preCaretTextRange.setEndPoint("EndToEnd", textRange);
                caretOffset = preCaretTextRange.text.length;
            }
            return caretOffset;
        },

        async getChangelogDetails(meeting_id){
            this.meeting_data = JSON.parse(sessionStorage.getItem('meet_details'));
            await this.$store.dispatch('changelogDetails',[this.$store.state.api, { "meeting_id":meeting_id}]);
        },

        async getMeetDetails(){
            let meeting_id = this.$route.params.meeting_id;
            await this.$store.dispatch('getMeetingMinutes',[this.$store.state.api, { "meeting_id": meeting_id}]);
            if(Object.keys(this.$store.state.meeting_minutes_details).length > 0){
                this.meeting_agendas = this.$store.state.meeting_minutes_details.agenda;
            } 
        },
        
        showEventElement(agendaIndex,data_index='',type=""){
            if(typeof(data_index) != "number"){
                data_index = -1;
                this.minutes_index = data_index;
            }
            this.hover_index = agendaIndex;
            if(data_index >= 0 ){
                this.minutes_index = data_index;
                this.minute_type = type;
            }
        },

        open_delete_popup(type,agenda_index='',minute_index = ''){
            let agenda_minutes_length = 0;

            if(Number.isInteger(agenda_index)){
                this.hover_index = agenda_index; 
                this.minutes_index = minute_index;
            }

            agenda_minutes_length = this.meeting_agendas[this.hover_index].minutes.length;
            if(this.minutes_index != -1){
            this.$emit('open_popup',[type,this.hover_index,this.minutes_index,agenda_minutes_length])}
        },

        blur_input(e){
            if(e.keyCode === 13){
                e.target.blur(); 
            }
        },

        make_textdecoration(event){
            switch(event.srcKey){
                case 'bold':
                    document.execCommand('bold');
                    break;
                case 'italic':
                    document.execCommand('italic');
                    break
                case 'underline':
                    document.execCommand('underline');
                    break;
                case 'bullets':
                    document.execCommand('insertUnorderedList',true,'');
                    break;
                case 'up': 
                    if(this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index-1]){
                        this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index-1].focus();
                        this.minutes_index -= 1;
                    }else if(this.$refs['instant_data_editor_'+(this.hover_index-1)]){
                        this.$refs['editor'][this.hover_index].focus();
                        this.hover_index -= 1;
                        this.minutes_index = this.meeting_agendas[this.hover_index].minutes.length;
                    }else if(!this.$refs['instant_data_editor_'+(this.hover_index-1)]){
                        this.$refs['editor'][this.hover_index].focus();
                        this.minutes_index = -1;
                    }
                    break;
                case 'down':
                    if(this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index+1]){
                        this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index+1].focus();
                        this.minutes_index += 1; 
                    }else if(this.$refs['instant_data_editor_'+(this.hover_index+1)]){
                        this.$refs['editor'][this.hover_index+1].focus();
                        this.hover_index += 1;
                        this.minutes_index = -1;
                    }
                    break;  
            }
        },
        
        input_decoration(index,data_index,focus){
            if(focus){
                this.$refs['instant_data_editor_'+index][data_index].className = "textarea_content grey";
            }else{
                this.$refs['instant_data_editor_'+index][data_index].className = "grey";
            }
        },

        agenda_input(index,focus){
            if(focus){
                this.$refs['editor'][index].className = "textarea_content m-0"
            }else{
                this.$refs['editor'][index].className = "m-0"
            }
        },

        next_element_focus(e){
            if(e.which == this.Constants.ENTER){
                e.preventDefault();
                if(this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index+1]){
                    this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index+1].focus();
                    this.minutes_index += 1; 
                }else if(this.$refs['instant_data_editor_'+(this.hover_index+1)]){
                    this.$refs['instant_data_editor_'+(this.hover_index+1)][0].focus();
                    this.hover_index += 1;
                    this.minutes_index = 0;
                }
            }
        },
        next_element_cursor_focus(e,index,data_index=-1){
            let el;
            this.hover_index = index;
            this.minutes_index = data_index;
            if(data_index == -1)
               el = this.$refs['editor'][this.hover_index];
            else
               el = this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index];
            if(e.which == this.Constants.DOWN_ARROW){ 
                let text = el.textContent;
                if(text.length == this.getCaretCharacterOffsetWithin(el)){
                    if(this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index+1]){
                        this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index+1].focus();
                        this.minutes_index += 1;
                    }else if(this.$refs['editor'][this.hover_index+1]){
                        this.$refs['editor'][this.hover_index+1].focus();
                        this.hover_index += 1;
                        this.minutes_index = -1;
                    }
                }
            }

            if(e.which == this.Constants.UP_ARROW){
                let start_pos =0;
                if(start_pos == this.getCaretCharacterOffsetWithin(el)){
                    if(this.minutes_index!= -1){
                        if(this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index-1]){
                            this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index-1].focus();
                            this.minutes_index -= 1;
                        }else if(this.$refs['instant_data_editor_'+(this.hover_index)]){
                            this.$refs['editor'][this.hover_index].focus();
                            this.minutes_index = -1;
                        }
                    }else{
                        if(this.$refs['instant_data_editor_'+(this.hover_index-1)]){
                            this.hover_index -= 1;
                            this.minutes_index =this.meeting_agendas[this.hover_index].minutes.length-1;
                            this.$refs['instant_data_editor_'+this.hover_index][this.minutes_index].focus();
                        }
                    }
                }
            }
        },

        async action_change(index,data_index){

            if(this.type == 'Instant_meeting'){
                this.meeting_data = JSON.parse(sessionStorage.getItem('meet_details'));
            }else{
                await this.$store.dispatch('getMeetingMinutes',[this.$store.state.api, { "meeting_id": this.$route.params.meeting_id}]);
                if(Object.keys(this.$store.state.meeting_minutes_details).length > 0){
                    this.meeting_data = this.$store.state.meeting_minutes_details;
                } 
            }

            let due_date = this.meeting_agendas[index].minutes[data_index].parameters.due_date ? this.meeting_agendas[index].minutes[data_index].parameters.due_date : '' ;

            let original_date_input = this.meeting_agendas[index].minutes[data_index].parameters.due_date ? this.meeting_agendas[index].minutes[data_index].parameters.due_date : '';

            if(this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_4 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_1 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_6){
            
                if(due_date==''){
                    due_date = moment().add(1, 'days').format("YYYY-MM-DD");
                }else if(moment(due_date).format("DD MMM YYYY") == "Invalid date"){
                    due_date = moment(due_date, "DD MM YYYY").format("YYYY-MM-DD")
                }else{
                    due_date = moment(due_date).format("YYYY-MM-DD");
                }

                if(original_date_input.match(/[a-zA-Z]/) != null){
                
                    if(!Number.isInteger(parseInt(original_date_input.slice(-2)))){
                        due_date = moment(original_date_input, "DD MMM YYYY").format("YYYY-MM-DD")
                    }else if(!Number.isInteger(parseInt(original_date_input.split("").reverse().join("").slice(-2)))){
                        due_date = moment(original_date_input, "MMM DD YYYY").format("YYYY-MM-DD")
                    }

                    if(Number.isInteger(parseInt(original_date_input.slice(-2))) || Number.isInteger(parseInt(original_date_input.slice(-1)))){

                        if(Number.isInteger(parseInt(original_date_input.slice(-4)))){
                            due_date = moment(original_date_input).format('YYYY-MM-DD')
                        }else{
                            due_date = moment(moment(original_date_input,'MMM DD').toString()).format("YYYY-MM-DD");
                        }
                        
                    }else if(Number.isInteger(parseInt(original_date_input.split("").reverse().join("").slice(-2)))){
                        due_date = moment(original_date_input, "DD MMM YYYY").format("YYYY-MM-DD")
                    }

                }else if(original_date_input.replace(/\D/g, "").length <= 4 && original_date_input != ""){
                    due_date = moment(original_date_input, "DD MM YYYY").format("YYYY-MM-DD")

                    if(due_date == "Invalid date"){
                        due_date = moment(original_date_input, "MM DD YYYY").format("DD MMM YYYY")
                    }
                }

                if(due_date == "Invalid date"){
                    document.getElementsByClassName("input_date_"+index+'_'+data_index)[0].value = "";
                    document.getElementsByClassName("input_date_"+index+'_'+data_index)[0].style.borderBottom = "1px solid red"
                    document.getElementsByClassName("input_date_"+index+'_'+data_index)[0].focus();
                    return false              
                }
            }
            let assigne_id = '';
            if(this.type == 'Instant_meeting'){
                assigne_id = this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_5 ? '' : this.meeting_agendas[index].minutes[data_index].parameters.asignee_id.org_member_id ? this.meeting_agendas[index].minutes[data_index].parameters.asignee_id.org_member_id : '';
            }else{
                assigne_id = this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_5 ? '' : this.meeting_agendas[index].minutes[data_index].asignee_id instanceof Object ? this.meeting_agendas[index].minutes[data_index].asignee_id.org_member_id : '';
            }

            let action_obj = {}

            if(this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_4){
                action_obj = {
                    "meeting_id":this.meeting_agendas[index].meeting_id,
                    "minutes":{
                        "id":this.meeting_agendas[index].minutes[data_index]._id,
                        "operation_type":2,
                        "minute_type":this.meeting_agendas[index].minutes[data_index].minute_type,
                        "parameters" : {
                            "asignee_id": assigne_id,
                            "priority": this.meeting_agendas[index].minutes[data_index].parameters.priority ? this.meeting_agendas[index].minutes[data_index].parameters.priority :'',
                            "status" : "Open"
                        }
                    }
                }
            }else{

                action_obj = {
                    "meeting_id":this.meeting_agendas[index].meeting_id,
                    "minutes":{
                        "id":this.meeting_agendas[index].minutes[data_index]._id,
                        "operation_type":2,
                        "minute_type":this.meeting_agendas[index].minutes[data_index].minute_type,
                        "parameters" : {
                            "asignee_id": assigne_id,
                            "status" : "Open"
                        }
                    }
                }
            }

            if(this.meeting_data.meeting.group_id){
                Object.assign(action_obj,{"group_id": this.meeting_data.meeting.group_id});
                Object.assign(action_obj,{"group_name": this.meeting_data.meeting.group_name});
            }

            if(this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_4 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_1 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_6){
                Object.assign(action_obj.minutes.parameters,{"due_date": due_date});
            }else if(this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_2 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_3 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_5){
                Object.assign(action_obj.minutes.parameters,{"due_date":  moment().format("YYYY-MM-DD")});
            }

            await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,action_obj]);

            if(this.type == 'Instant_meeting'){
                if(Object.keys(this.$store.state.added_agenda_data).length > 0){
                    if(this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_4){
                        this.meeting_agendas[index].minutes[data_index].parameters.priority = this.$store.state.added_agenda_data.parameters.priority;
                    }

                    if(this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_4 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_1 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_6){
                        this.meeting_agendas[index].minutes[data_index].parameters.due_date =  due_date;
                    }else if(this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_2 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_3 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_5){
                    this.meeting_agendas[index].minutes[data_index].parameters.due_date =  moment().format("YYYY-MM-DD");
                    }
                    
                }
    
                this.$forceUpdate();
                sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
                this.meeting_agendas = JSON.parse(sessionStorage.getItem('agenda_details'));

                //this.$nextTick is used  to updated the DOM  Without refresh the page

                this.$nextTick(() => {
                    if(this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_4 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_1 || this.meeting_agendas[index].minutes[data_index].minute_type == this.Constants.TASK_TYPE.TYPE_6){
                        document.getElementsByClassName('input_date_'+index+'_'+data_index)[0].value =
                        moment(due_date).format('DD MMM YYYY');
                    }
                })

            }else{
                await this.getMeetDetails();
                this.$forceUpdate();
            }     
        },

        async instant_meet_count_change(index,data_index,type){

            if(this.type == 'Instant_meeting'){
                this.meeting_data = JSON.parse(sessionStorage.getItem('meet_details'));
            }else{
                await this.$store.dispatch('getMeetingMinutes',[this.$store.state.api, { "meeting_id": this.$route.params.meeting_id}]);
                if(Object.keys(this.$store.state.meeting_minutes_details).length > 0){
                    this.meeting_data = this.$store.state.meeting_minutes_details;
                } 
            }

            this.add_click_enable = false;
            
            if(index== 'null'){
                index = this.hover_index;
            }

            let minutesObj = {
                "meeting_id":this.meeting_agendas[index].meeting_id,
                "minutes":{
                    "agenda_id": this.meeting_agendas[index]._id,
                    "operation_type":1,
                    "minute_type": this.Constants.TASK_TYPE.TYPE_5,
                    "parameters" : {
                        "asignee_id" : "",
                        "status" : "Open",
                        "due_date": moment().add('1','days').format("YYYY-MM-DD")
                    }
                }
            }

            if(this.meeting_data.meeting.group_id){
                Object.assign(minutesObj,{"group_id": this.meeting_data.meeting.group_id});
                Object.assign(minutesObj,{"group_name": this.meeting_data.meeting.group_name});
            }

            if(type == "add"){
                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,minutesObj]);

                if(Object.keys(this.$store.state.added_agenda_data).length > 0 &&  this.type == 'Instant_meeting'){
                    this.meeting_agendas[index].minutes.push(this.$store.state.added_agenda_data.minutes);
                }             
            }else{
                let delete_obj = {
                    "meeting_id":this.meeting_agendas[index].meeting_id,
                    "minutes":{
                        "id":this.meeting_agendas[index].minutes[data_index]._id,
                        "operation_type":3
                    }
                }

                this.meeting_agendas[index].minutes.splice(data_index,1);
                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,delete_obj]);
                
            }
            this.$forceUpdate();

            if(this.type == 'Instant_meeting'){
                sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
                this.meeting_agendas = JSON.parse(sessionStorage.getItem('agenda_details'))
            }else{
                await this.getMeetDetails();
            }

            if(!this.tab_row_add) {
                let _this = this;
                window.setTimeout(function(){
                    _this.$refs['instant_data_editor_'+index][_this.meeting_agendas[index].minutes.length-1].focus();
                },100);
            }
            this.add_click_enable = true;
            this.hover_index = index;
            this.minutes_index = this.meeting_agendas[index].minutes.length-1;  
            this.tab_row_add = false;
            this.delete_meeting_popup = false;
        },

        async dragend(e,index){
            let current_position = e.oldIndex+1
            let target_position = e.newIndex+1
            let move = target_position > current_position ? "down" : "up"
            
            let shuffle_obj = {
                "minute_id": this.meeting_agendas[index].minutes[e.newIndex]._id,
                "agenda_id":this.meeting_agendas[index]._id,
                "current_position":current_position,
                "target_position" : target_position,
                "move":move
            }

            await this.$store.dispatch('minuteShuffle',[this.$store.state.api,shuffle_obj]);

            if(Object.keys(this.$store.state.shuffle_minute).length > 0 ){
                this.meeting_agendas[index].minutes[e.oldIndex].minute_order = this.$store.state.shuffle_minute.minute_order;
            }

            let dragged_minute = this.meeting_agendas[index].minutes[e.newIndex];

            this.meeting_agendas[index].minutes.splice(e.newIndex, 1);

            this.meeting_agendas[index].minutes.splice(e.newIndex,0,dragged_minute);

            this.$forceUpdate();

            if(this.type == 'Instant_meeting'){
                sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
            }
        },

        async update_data(e,agenda_index,data_index){
    
            if(this.type == 'Instant_meeting'){
                this.meeting_data = JSON.parse(sessionStorage.getItem('meet_details'));
            }else{
                await this.$store.dispatch('getMeetingMinutes',[this.$store.state.api, { "meeting_id": this.$route.params.meeting_id}]);
                if(Object.keys(this.$store.state.meeting_minutes_details).length > 0){
                    this.meeting_data = this.$store.state.meeting_minutes_details;
                } 
            }

            if(data_index != null){  
                let minutesObj = {
                    "meeting_id":this.meeting_agendas[agenda_index].meeting_id,
                    "minutes":{
                        "id": this.meeting_agendas[agenda_index].minutes[data_index]._id,
                        "operation_type":2,
                        "description" : e.srcElement.innerHTML
                    }
                }

                if(this.meeting_data.meeting.group_id){
                    Object.assign(minutesObj,{"group_id": this.meeting_data.meeting.group_id});
                    Object.assign(minutesObj,{"group_name": this.meeting_data.meeting.group_name});
                }

                await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,minutesObj]);

                if(Object.keys(this.$store.state.added_agenda_data).length > 0){
                    this.$store.state.added_agenda_data.parameters = this.meeting_agendas[agenda_index].minutes[data_index].parameters;
                    this.meeting_agendas[agenda_index].minutes[data_index].description = e.srcElement.innerHTML;
                }
            }else{
                if(agenda_index >= 0){
                    let agendaObj = {
                    "meeting_id": this.meeting_agendas[agenda_index].meeting_id,
                        "agenda":{
                            "id":  this.meeting_agendas[agenda_index]._id,
                            "operation_type":2,
                            "agenda_item_description": e.srcElement.innerHTML,
                        }
                    }

                    await this.$store.dispatch('saveAgendaMeeting',[this.$store.state.api,agendaObj]);

                    if(Object.keys(this.$store.state.added_agenda_data).length > 0){
                        this.$store.state.added_agenda_data.minutes =  this.meeting_agendas[agenda_index].minutes;
                        this.meeting_agendas[agenda_index] = this.$store.state.added_agenda_data;
                    }
                }
            }

            if(this.type == 'Instant_meeting'){
                sessionStorage.setItem('agenda_details', JSON.stringify(this.meeting_agendas));
            }
        },
    },

    filters:{
        time_12hr(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("hh:mm A");
        },

        day_month_date(date_time,time_zone){
            return moment(date_time).utc().tz(time_zone).format("ddd MMM DD, YYYY");
        },

        regular_format(date){
            return moment(date).format("DD MMM, YYYY");
        },
    }
}